<template>
  <div class="cursor-pointer font-bold leading-5 text-cs-black dark:text-white" @click="handleClick">
    <span v-if="username" class="truncate block">
      {{ username }}
    </span>
    <slot />
  </div>
</template>

<script lang="ts" setup>
import {INTERNAL_EVENT_USER_NOT_REGISTERED} from '~/constants/internal'
import {useIsUserInSession} from '~/composables/auth/useStateCSpotSession'
import {
  GTM_EVENT_GUEST_ACCESS_LIVESTREAM_VIEW_PROFILE,
  GTM_EVENT_GUEST_USER_PROFILE,
  GTM_EVENT_TYPE_CLICK
} from '~/constants/gtmEvent'

interface ComponentProps {
  username?: string
  id?: string
  isLivestream?: boolean
}

const props = defineProps<ComponentProps>()

const {$eventEmitter, $eventTracker} = useNuxtApp()
const thereIsSession = useIsUserInSession()

const handleClick = () => {
  if (!thereIsSession.value) {
    if (props.isLivestream) {
      $eventTracker.triggerEvent(GTM_EVENT_GUEST_ACCESS_LIVESTREAM_VIEW_PROFILE, GTM_EVENT_TYPE_CLICK)
    } else {
      $eventTracker.triggerEvent(GTM_EVENT_GUEST_USER_PROFILE, GTM_EVENT_TYPE_CLICK)
    }
    $eventEmitter(INTERNAL_EVENT_USER_NOT_REGISTERED)
  } else {
    navigateTo(`/user/${props.id}`)
  }
}
</script>
